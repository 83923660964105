// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cost-of-moving-js": () => import("./../../../src/pages/cost-of-moving.js" /* webpackChunkName: "component---src-pages-cost-of-moving-js" */),
  "component---src-pages-estate-agents-in-manchester-js": () => import("./../../../src/pages/estate-agents-in-manchester.js" /* webpackChunkName: "component---src-pages-estate-agents-in-manchester-js" */),
  "component---src-pages-free-the-fees-js": () => import("./../../../src/pages/free-the-fees.js" /* webpackChunkName: "component---src-pages-free-the-fees-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-financial-services-privacy-policy-index-js": () => import("./../../../src/pages/legal/financial-services-privacy-policy/index.js" /* webpackChunkName: "component---src-pages-legal-financial-services-privacy-policy-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-unsubscribe-js": () => import("./../../../src/pages/legal/unsubscribe.js" /* webpackChunkName: "component---src-pages-legal-unsubscribe-js" */),
  "component---src-pages-lp-sell-your-home-for-free-js": () => import("./../../../src/pages/lp/sell-your-home-for-free.js" /* webpackChunkName: "component---src-pages-lp-sell-your-home-for-free-js" */),
  "component---src-pages-mortgages-mortgage-calculator-js": () => import("./../../../src/pages/mortgages/mortgage-calculator.js" /* webpackChunkName: "component---src-pages-mortgages-mortgage-calculator-js" */),
  "component---src-pages-refer-a-friend-js": () => import("./../../../src/pages/refer-a-friend.js" /* webpackChunkName: "component---src-pages-refer-a-friend-js" */),
  "component---src-pages-selling-fees-js": () => import("./../../../src/pages/selling/fees.js" /* webpackChunkName: "component---src-pages-selling-fees-js" */),
  "component---src-pages-selling-how-it-works-js": () => import("./../../../src/pages/selling/how-it-works.js" /* webpackChunkName: "component---src-pages-selling-how-it-works-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-mortgages-js": () => import("./../../../src/pages/services/mortgages.js" /* webpackChunkName: "component---src-pages-services-mortgages-js" */),
  "component---src-pages-strike-in-manchester-js": () => import("./../../../src/pages/strike-in-manchester.js" /* webpackChunkName: "component---src-pages-strike-in-manchester-js" */),
  "component---src-pages-why-strike-js": () => import("./../../../src/pages/why-strike.js" /* webpackChunkName: "component---src-pages-why-strike-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/Page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */)
}

