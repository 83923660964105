import React from 'react';
import { AuthProvider } from '@strikelabs/vega';
import { init as apm } from '@elastic/apm-rum';

export const onClientEntry = () => {
  if (process.env.GATSBY_SERVICE_NAME && process.env.GATSBY_ELASTIC_APM_URL) {
    apm({
      serviceName: process.env.GATSBY_SERVICE_NAME,
      serverUrl: process.env.GATSBY_ELASTIC_APM_URL,
      pageLoadTransactionName: window.location.pathname,
    });
  }

  if (process.env.GATSBY_MOCK === 'true') {
    import('@strikelabs/nibiru').then(module =>
      module.initMockServer({
        environment: process.env.GATSBY_MOCK_ENVIRONMENT,
        urlPrefix: process.env.GATSBY_API_URL,
      })
    );
  }
};

export const onInitialClientRender = () => {
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'optimize.activate' });
  }

  document.documentElement.classList.remove('async-hide');
};

export const onRouteUpdate = ({ prevLocation }) => {
  // Run when route changes, but not on first load
  if (window.dataLayer && prevLocation) {
    window.dataLayer.push({ event: 'optimize.activate' });
  }
};

let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};
/**
 * Event listener for refreshing the page if a loading chunk fails based
 * on a user navigating when chunk paths have changed during a deployment.
 */
window.addEventListener('unhandledrejection', event => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const wrapPageElement = ({ element }) => {
  return (
    <AuthProvider baseUrl={process.env.GATSBY_API_URL}>{element}</AuthProvider>
  );
};

/**
 * When a Gatsby build is released, watch for update to service
 * worker and hard reload users browser to bust their local cache.
 */
export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
